import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import styled from "styled-components"

export default function Featured(props) {
  const { title, description, thumbnail, slug } = props
  return (
    <Link
      style={{
        boxShadow: `none`,
        textDecoration: `none`,
        color: `inherit`,
      }}
      to={slug}
    >
      <FeaturedArticle>
        <Img fluid={thumbnail} />
        <div>
          <h2>{title}</h2>
          <ArticleDesc
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </div>
      </FeaturedArticle>
    </Link>
  )
}
const FeaturedArticle = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.2fr;
  grid-gap: 25px;
  margin-bottom: 50px;
  h2 {
    margin-top: 0;
    line-height: 120%;
  }
  p {
    font-size: 18px;
    line-height: 135%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 425px) {
    margin-bottom: 25px;
  }
`
const ArticleDesc = styled.p`
  font-size: 15px;
`
const ArticleDate = styled.small`
  font-size: 12px;
`
