import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Featured from "../components/featured"
import SubFeatured from "../components/subfeatured"
import SEO from "../components/seo"
import Img from "gatsby-image"

import styled from "styled-components"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allArticles.edges
    const featuredArticle = data.featuredArticle
    const subFeaturedArticles = data.subFeaturedArticles.edges
    return (
      <LayoutContainer>
        <SEO
          title="Nucleotype"
          description="Medical Educational Articles and Interactive Content"
          location="home"
        />

        <HeaderContainer>
          <h1>
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              <div className="header-inner">
                <div>NUCLEOTYPE</div>
              </div>
            </Link>
          </h1>
          <p>
            A publication by Medzcool, for healthcare students and
            professionals.{" "}
          </p>
        </HeaderContainer>

        <Featured
          title={featuredArticle.frontmatter.title}
          description={
            featuredArticle.frontmatter.description || featuredArticle.excerpt
          }
          thumbnail={
            featuredArticle.frontmatter.thumbnail.childImageSharp.fluid
          }
          slug={featuredArticle.fields.slug}
        />

        <SubFeatured subFeaturedArticles={subFeaturedArticles} />

        <ArticleList>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <article key={node.fields.slug}>
                <header>
                  <h3>
                    <Link
                      style={{
                        boxShadow: `none`,
                        textDecoration: `none`,
                        color: `inherit`,
                      }}
                      to={node.fields.slug}
                    >
                      {title}
                    </Link>
                  </h3>

                  <p>{node.frontmatter.date}</p>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt,
                    }}
                  />
                </section>
              </article>
            )
          })}
        </ArticleList>
      </LayoutContainer>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    featuredArticle: mdx(
      frontmatter: {
        title: {
          eq: "Vital Signs - (Heart Rate, Blood Pressure, Respiratory Rate, Oxygen Saturation and Temperature)"
        }
      }
    ) {
      fields {
        slug
      }
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
            fixed(height: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    subFeaturedArticles: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          title: {
            in: ["ABCDE Assessment in Trauma", "Salter Harris Fracture Classification"]
          }
        }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allArticles: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          title: {
            nin: [
              "Vital Signs - (Heart Rate, Blood Pressure, Respiratory Rate, Oxygen Saturation and Temperature)"
              "ABCDE Assessment in Trauma"
              "Salter Harris Fracture Classification"
            ]
          }
        }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

const LayoutContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  padding: 2.625rem 1.3125rem;
`

const HeaderContainer = styled.div`
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 75px;
  h1 {
    font-family: futura-pt, sans-serif;
    font-weight: 700;
    font-size: 3.9rem;
    line-height: 4.375rem;
    margin-top: 0px;
    margin-bottom: 0px;
    .header-inner {
      max-width: 1200px;
      margin: auto;
    }
    .gray {
      color: gray;
    }
    .black {
      color: black;
    }
  }
  p {
    font-size: 18px;
    margin-top:0;
  }
  @media (max-width: 768px) {
    h1 {
      font-size: 50px;
      line-height: normal;
    }
  }
  @media (max-width: 425px) {
    h1 {
      font-size: 40px;
      line-height: normal;
    }
  }
`

const ArticleList = styled.div`
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  h3 {
    margin-bottom: 0px;
  }
  p {
    font-size: 18px;
    line-height: 135%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`
