import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import styled from "styled-components"

export default function SubFeatured(props) {
  const { subFeaturedArticles } = props
  return (
    <SubFeaturedContainer>
      {subFeaturedArticles.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={node.fields.slug}
            key={node.fields.slug}
          >
            <article>
              <Img fluid={node.frontmatter.thumbnail.childImageSharp.fluid} />
              <header>
                <h3>{title}</h3>
              </header>
              <section>
                <ArticleDesc
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </section>
            </article>
          </Link>
        )
      })}
    </SubFeaturedContainer>
  )
}
const SubFeaturedContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  h3 {
    margin-top: 15px;
    margin-bottom: 10px;
    line-height: 120%;
    color: black;
  }
  p {
    font-size: 18px;
    line-height: 135%;
  }
  @media (max-width: 768px) {
    grid-gap: 25px;
  }
  @media (max-width: 425px) {
    grid-template-columns: 1fr;
  }
`
const ArticleDesc = styled.p`
  font-size: 15px;
`
const ArticleDate = styled.small`
  font-size: 12px;
`
