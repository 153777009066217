/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import styled from "styled-components"

const Bio = () => {
  return (
    <div
      style={{
        display: `flex`,
      }}
    >
      <BioContainer>
        A publication by Medzcool, for healthcare students and professionals.
      </BioContainer>
    </div>
  )
}

export default Bio

const BioContainer = styled.p`
  font-family: futura-pt, sans-serif;
  margin-top: 0px;
  font-size: 16px;
`
