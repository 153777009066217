import React from "react"
import { Link } from "gatsby"
import Header from "./Header"
import "./layout.css"

import styled from "styled-components"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <StyledH1
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            <Header locationType="home" />
          </Link>
        </StyledH1>
      )
    } else {
      header = (
        <h3
          style={{
            fontFamily: `futura-pt, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            <Header />
          </Link>
        </h3>
      )
    }
    return (
      <LayoutContainer>
        <div className="inner-container">
          <header>{header}</header>
          <main>{children}</main>
        </div>
      </LayoutContainer>
    )
  }
}

export default Layout

const LayoutContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  padding: 2.625rem 1.3125rem;
  position: relative;
`

const StyledH1 = styled.h1`
  line-height: 110%;
  margin-top:0;
`
